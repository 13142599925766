.inline-badge {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--color-light);

  &.primary {
    background-color: var(--color-primary);
  }
  &.secondary {
    background-color: var(--color-secondary);
  }
  &.error {
    background-color: var(--color-error);
  }
  &.warning {
    background-color: var(--color-warning);
  }
  &.success {
    background-color: var(--color-success);
  }
}