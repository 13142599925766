.user-action-page-frame-v2 {
    position: relative;
    background-color: var(--color-bodyBackground);
    overflow: hidden;

    .corner-animation {
        position: absolute;
        z-index: 1;
        width: 300vw;
        height: 100vh;
        transform: rotate(-30deg) translateX(10vw) translateY(35vw);
        box-shadow: 6px -2px 10px rgba(var(--color-primary-rgb), 0.5);
        
        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
        background: linear-gradient(-45deg, var(--color-secondary), var(--color-secondary), var(--color-primary), var(--color-secondary));
        background-size: 250% 250%;
        animation: gradient 15s ease infinite;
        
        &.xs {
            transform: rotate(-40deg) translateX(-30vw) translateY(105vw);
        }
        &.sm {
            transform: rotate(-40deg) translateX(-30vw) translateY(65vw);
        }
        &.md {
            transform: rotate(-40deg) translateX(-30vw) translateY(50vw);
        }
    }

    .card-wrapper {
        position: relative;
        z-index: 2;
        max-height: 100vh;
        width: 100%;
        overflow: auto;
        box-sizing: border-box;
        padding: 30px 0;
        
    }
    
    .logo {
        height: auto;
        margin-bottom: 30px;
    
        &.full {
            width: 100%;
            max-width: 100%;
        }
        &.small {
            width: 60%;
            max-width: 60%;
        }

    }
}