.metrics-square {
    width: 100%;
    height: 100%;
    background-color: var(--color-secondary);
    box-shadow: rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    padding: 8px;
    position: relative;

    &.has-image {
        row-gap: 5px;
    }

    .expand-metrics {
        position: absolute;
        bottom: 2px;
        right: 2px;
    }
}