.geo-map-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &.full-height {
      height: 100%;
  }

  .geo-map {
      height: 100%;
      flex-grow: 1;
      border-radius: 10px;
  }

  .geo-marker-label {
      color: var(--color-text) !important;
      padding: 4px;
      border-radius: 4px;
      background-color: var(--color-light);
      position: relative;
      top: 42px;
      box-shadow: 2px 3px 10px 0px rgb(0 0 0 / 12%);
      font-weight: 500;
      border: 1px solid var(--color-shade);
      font-size: 14px !important;
  }
}
