.moving-landing-page {
    position: relative;
    background-color: var(--color-bodyBackground);
    overflow: hidden;
    padding-bottom: 80px;
    min-height: 100vh;

    .dark-block {
        position: fixed;
        z-index: 0;
        width: 300vw;
        height: 125vh;
        transform: rotate(-30deg) translateX(-3vw) translateY(-21vw);
        box-shadow: 6px -2px 10px rgba(var(--color-primary-rgb), 0.5);

        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
        background: linear-gradient(-45deg, var(--color-secondary), var(--color-secondary), var(--color-primary), var(--color-secondary));
        background-size: 250% 250%;
        animation: gradient 15s ease infinite;

        &.xs {
            transform: rotate(-40deg) translateX(-60vw) translateY(64vw);
        }
        &.sm {
            transform: rotate(-40deg) translateX(0vw) translateY(-10vw);
        }
        &.md {
            transform: rotate(-40deg) translateX(0vw) translateY(-17vw);
        }
        &.lg {
            transform: rotate(-30deg) translateX(0vw) translateY(-20vw);
        }
    }
}