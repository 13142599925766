* {
  &::-webkit-scrollbar {
    height: 5px;
    width: 10px;
    background-color: var(--color-shade);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-lightGrey);
    border-radius: 8px;
  }

  &.small-scrollbars {
    &::-webkit-scrollbar {
      width: 3px;
      background-color: var(--color-shade);
    } 
  }
}

ol:not(.custom-numbering) {
  counter-reset: item;

  li {
    display: block;
    padding-bottom: 12px;
    
    &:before {
      content: counters(item, ".") " ";
      counter-increment: item;
      float: left;
      padding-right: 15px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
  }
}

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#dashboard-scroll-element {
  scroll-behavior: smooth !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

[data-env] {
  display: none;
  visibility: hidden;
}

img {
  max-width: 100%;
  max-height: 100%;

  &.center {
    margin: auto;
    display: block;
  }
}
a {
  text-decoration: none;
  color: initial;
}
form {
  flex: 1;
}

.pointer {
  cursor: pointer;
}
.link {
  cursor: pointer;
  color: var(--color-primary);
  &:hover {
    text-decoration: underline;
  }
}
.no-select {
  -webkit-user-select: none;
  user-select: none;
}

.master-page-container {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .master-page-body {
    background-color: #f8f8f8;
    display: flex;
    flex: 1;
    position: relative;
    max-height: 100%;
    overflow: hidden;
 
    .master-page-outlet-container {
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
        @media screen and (max-width: 899px) {
            padding: 30px 15px;
        }
        @media screen and (min-width: 900px) {
            padding: 50px;
        }

        padding-bottom: 0px !important;
    }
  }
}

.grid-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stack-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}

.full-page {
  height: 100vh;
  width: 100vw;
}
.absolute-full-page {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.color-lightGrey {
  color: var(--color-lightGrey) !important;
}
.color-warning {
  color: var(--color-warning) !important;
}
.color-primary {
  color: var(--color-primary) !important;
}
.color-error {
  color: var(--color-error) !important;
}
.color-shade {
  color: var(--color-shade) !important;
}

.line-behind {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .line-text {
    padding-left: 15px;
    padding-right: 15px;
    background-color: var(--color-light);
    z-index: 1;
  }

  &::before {
    content: ' ';
    width: 100%;
    z-index: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-top: 1px solid var(--color-lightGrey);
  }
}

.no-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

// =========================== MATERIAL UI  ===========================
.MuiFormControl-root {
  &.native-look {
    margin: 0;

    .MuiInputBase-root {
      padding: 0;

      input {
        padding: 0;
      }
      fieldset {
        padding:0;
        border: none;
      }
    }
  }
}

.MuiChip-root {
  &.iconOnly {
    .MuiChip-label {
      padding-right: 0;
    }
  }
}

.MuiStepConnector-root {
  &.Mui-active, &.Mui-completed {

    .MuiStepConnector-line {
      border-color: var(--color-primary);
    }
  }
}
.MuiStepLabel-root {
  .MuiStepLabel-labelContainer {
    .MuiStepLabel-label {
      font-weight: normal;
    }
  }
}
.MuiAccordion-root {
  &::before {
    display: none !important;
  }
}
// =========================== MATERIAL UI  ===========================

// =========================== RECHARTS ===========================
.recharts-surface {
  text {
    tspan {
      font-size: 10px;
    }
  }
}
.recharts-default-tooltip {
  border: none;
  border-radius: 10px;
  background-color: var(--color-light);
  box-shadow: rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px;

  .recharts-tooltip-label {
    font-size: 0.85rem;  
  }
}
.recharts-legend-item-text {
  font-size: 0.7rem;
}
.recharts-tooltip-wrapper, .recharts-layer {
  outline: none;
}
.recharts-legend-wrapper {
  .chart-legends {
    overflow: auto;
    padding-left: 25px;
    padding-right: 5px;
  }
}
// =========================== RECHARTS ===========================