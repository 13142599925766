.site-header {
    display: flex;
    column-gap: 20px;
    position: sticky;
    top: 0;
    box-sizing: border-box;
    width: 100vw;
    padding: 20px 30px;
    background-color: var(--color-light);
    box-shadow: 0 3px 6px rgb(0 0 0 / 5%);
    z-index: 2;

    a {
        display: flex;
    }
    .luxon-logo {
        height: 40px;
    }

    .user-actions {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
    }
}